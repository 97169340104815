import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { IResponse, IResponseData } from 'src/app/model/interfaces/response.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { AlertService } from '../alert/alert.service';
import { IExternalAdminChangeAccount, IExternalAdminGetAccounts } from './external-admin.interface';
import { UserService } from '@services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalAdminService {
  constructor(
    private alert: AlertService,
    private http: HttpClient,
    private operator: CustomOperators,
    private userService: UserService
  ) {}

  /**
   * Gets the accounts
   * @returns Data of external admin account
   */
  getAccounts(): Observable<IExternalAdminGetAccounts[]> {
    return this.http.get<IResponseData<IExternalAdminGetAccounts[]>>('/externalAdmin/getAccounts').pipe(
      map(value => {
        if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
        return value.data;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !err.ok) {
          this.userService.logout();
        }
        throw err;
      })
    );
  }

  /**
   * Changes the account
   * @param accountId - The account id of user
   * @returns Data of changed account
   */
  changeAccount(accountId: string): Observable<IExternalAdminChangeAccount> {
    return this.http
      .post<IResponseData<IExternalAdminChangeAccount>>('/externalAdmin/changeAccount', { accountId })
      .pipe(this.operator.extractResponseData());
  }
}
